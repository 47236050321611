define("gbs-admin/components/shared/filter-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared c-filter-bar'],
    actions: {
      filterResource: function filterResource() {
        var _this = this;

        var filterParams = {};
        this.get('filters').forEach(function (item) {
          filterParams[item] = _this.get('params')[item];
        });
        this.attrs.onFilterResource(filterParams);
      },
      resetFilters: function resetFilters() {
        var filterParams = {};
        this.get('filters').forEach(function (item) {
          filterParams[item] = null;
        });
        this.attrs.onFilterResource(filterParams);
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/routes/authenticated/companies/modal/edit", ["exports", "gbs-admin/routes/view"], function (_exports, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _view.default.extend({
    modelName: 'company',
    parent: 'authenticated.companies.list',
    include: 'image'
  });

  _exports.default = _default;
});
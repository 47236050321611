define("gbs-admin/models/partner-user", ["exports", "gbs-ember-shared/models/company", "ember-data/attr", "ember-data/relationships"], function (_exports, _company, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _company.default.extend((_Model$extend = {
    company_id: (0, _attr.default)('string'),
    company_title: (0, _attr.default)('string')
  }, _defineProperty(_Model$extend, "company_title", (0, _attr.default)('string')), _defineProperty(_Model$extend, "redemptions_count", (0, _attr.default)('number')), _defineProperty(_Model$extend, "user", (0, _relationships.belongsTo)('user')), _Model$extend));

  _exports.default = _default;
});
define("gbs-admin/models/admin", ["exports", "gbs-ember-shared/models/admin"], function (_exports, _admin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _admin.default.extend({
    display: Ember.computed('first_name', 'last_name', function () {
      return this.first_name + " " + this.last_name;
    })
  });

  _exports.default = _default;
});
define("gbs-admin/components/shared/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    classNames: ['shared', 'multiple-select'],
    computedModels: Ember.computed('models', 'value', 'models.length', 'value.length', function () {
      var models = this.get('models');
      var value = this.get('value');

      if (_typeof(models) === 'object') {
        models.forEach(function (item) {
          if (item.set) {
            if (value && value.findBy) {
              var status = value.findBy('id', item.id) ? true : false;
              item.set('selected', status);
            } else {
              item.set('selected', false);
            }
          } else {
            if (!value) {
              Ember.set(item, 'selected', false);
            } else {
              Ember.set(item, 'selected', value.indexOf(item.id) !== -1);
            }
          }
        });
      }

      return this.get('models');
    }),
    actions: {
      change: function change() {
        var array = this.get('models').filter(function (item) {
          return item.selected === true;
        });

        if (!this.get('models.firstObject').store) {
          array = array.map(function (item) {
            return item.id;
          });
        }

        this.set('value', array);
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/partner-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ThHwZx2J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"partner-users/list\",[],[[\"@model\",\"@params\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"params\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/partner-users.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/routes/authenticated/gift-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      status: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      filter: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.filter === "false") {
        return this.store.query('gift-order', {
          limit: 100,
          skip: params.page,
          include: 'owner,employee,company'
        });
      } else {
        return this.store.query('gift-order', {
          limit: 100,
          skip: params.page,
          status: params.status,
          include: 'owner,employee,company'
        });
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/transforms/object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(value) {
      if (_typeof(value) !== 'object') {
        return {};
      } else {
        return value;
      }
    },
    serialize: function serialize(value) {
      if (_typeof(value) !== 'object') {
        return {};
      } else {
        return value;
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/initializers/segment", ["exports", "gbs-admin/services/segment"], function (_exports, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    app.register('service:segment', _segment.default);
    app.inject('route', 'segment', 'service:segment');
    app.inject('component', 'segment', 'service:segment');
  }

  var _default = {
    name: 'segment',
    initialize: initialize
  };
  _exports.default = _default;
});
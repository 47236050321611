define("gbs-admin/components/offers/edit", ["exports", "gbs-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    classNames: ['offers', 'edit'],
    modelName: "offers",
    store: Ember.inject.service(),
    benefits: Ember.computed(function () {
      return this.get('store').peekAll('benefit');
    }),
    actions: {
      deleteAllCodes: function deleteAllCodes(model) {
        if (confirm("Are you sure?")) {
          console.log(model);
          model.set('codes', null);
          model.save().then(function () {
            model.reload();
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/components/users/list", ["exports", "gbs-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _list.default.extend({
    classNames: ['users', 'w-2'],
    filters: ['companyName', 'firstName', 'lastName', 'email'],
    isSyncing: false,
    actions: {
      filterResource: function filterResource(filterParams) {
        this.onFilterResource(filterParams);
      },
      syncToCustomerIo: function syncToCustomerIo() {
        var _this = this;

        _this.set('isSyncing', true);

        this.get('model.firstObject').syncCustomerIo().then(function (response) {
          _this.set('isSyncing', false);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/components/companies/list", ["exports", "gbs-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _list.default.extend({
    classNames: ['companies', 'w-1'],
    sort: function sort(a, b) {
      return a.get('title').localeCompare(b.get('title'));
    }
  });

  _exports.default = _default;
});
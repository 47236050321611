define("gbs-admin/models/gift", ["exports", "gbs-ember-shared/models/gift"], function (_exports, _gift) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _gift.default.extend({
    display: Ember.computed('title', function () {
      return this.title;
    })
  });

  _exports.default = _default;
});
define("gbs-admin/components/benefits/edit", ["exports", "gbs-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    classNames: ['benefits', 'edit'],
    modelName: "benefits",
    store: Ember.inject.service(),
    emailLead: "Use {{customer}}, {{company}}, {{email}} params",
    callToActions: Ember.computed(function () {
      return [{
        display: 'Code',
        id: 'code'
      }, {
        display: 'Unique Code',
        id: 'unique-code'
      }, {
        display: 'Link',
        id: 'link'
      }, {
        display: 'Form',
        id: 'form'
      }, {
        display: 'Built-in Form',
        id: 'internal-form'
      }];
    }),
    customFieldTypes: Ember.computed(function () {
      return [{
        display: 'Email',
        id: 'email'
      }, {
        display: 'Mobile',
        id: 'mobile'
      }];
    })
  });

  _exports.default = _default;
});
define("gbs-admin/components/products/edit", ["exports", "gbs-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    classNames: ['products', 'edit'],
    modelName: "products"
  });

  _exports.default = _default;
});
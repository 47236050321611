define("gbs-admin/components/shared/list", ["exports", "gbs-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _section.default.extend({
    classNames: ['shared', 'list'],
    add: true,
    length: true,
    owner: true,
    filteredModels: Ember.computed.filter('model', function (item) {
      return !item.get('isNew');
    })
  });

  _exports.default = _default;
});
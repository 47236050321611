define("gbs-admin/models/gift-order", ["exports", "gbs-ember-shared/models/gift-order"], function (_exports, _giftOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _giftOrder.default.extend({});

  _exports.default = _default;
});
define("gbs-admin/components/orders/edit", ["exports", "gbs-admin/components/shared/edit", "gbs-admin/mixins/periods"], function (_exports, _edit, _periods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend(_periods.default, {
    classNames: ['orders', 'edit'],
    modelName: "orders",
    column: false,
    request: Ember.inject.service(),
    actions: {
      charge: function charge() {
        var url = this.get('request').url() + "checkout/charge/" + this.get('model.id');
        window.open(url, '_blank');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.statuses = [{
        id: "pending",
        display: "Pending"
      }, {
        id: "cancelled",
        display: "Cancelled"
      }, {
        id: "completed",
        display: "Completed"
      }];
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/gift-orders/modal/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RudBJ/Ax",
    "block": "{\"symbols\":[],\"statements\":[[7,\"gift-orders/edit\",[],[[\"@embedded\",\"@model\",\"@destination\"],[true,[27,[26,0,\"AppendSingleId\"],[]],\"authenticated.gift-orders\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/gift-orders/modal/add.hbs"
    }
  });

  _exports.default = _default;
});
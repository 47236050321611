define("gbs-admin/controllers/authenticated/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['active', 'imported', 'page', 'filter', 'firstName', 'lastName', 'email'],
    firstName: null,
    lastName: null,
    email: null
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/employees/modal/edit/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N3NXBGHd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shared/delete\",[],[[\"@model\",\"@label\",\"@return\",\"@destination\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,0,\"AppendSingleId\"],[\"display\"]],\"authenticated.employees.modal.edit\",\"authenticated.employees\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/employees/modal/edit/delete.hbs"
    }
  });

  _exports.default = _default;
});
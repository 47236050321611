define("gbs-admin/components/partner-users/edit", ["exports", "gbs-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    classNames: ['companies', 'edit'],
    modelName: "partner-users",
    store: Ember.inject.service(),
    allUsers: Ember.computed(function () {
      return this.get('store').query('user', {
        limit: false
      });
    })
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/admins-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPp/mYj8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shared/loader\",[],[[\"@padding\"],[true]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/admins-loading.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/mixins/periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    periods: Ember.computed(function () {
      return [{
        id: "monthly",
        display: "Monthly"
      }, {
        id: "yearly",
        display: "Yearly"
      }];
    })
  });

  _exports.default = _default;
});
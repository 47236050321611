define("gbs-admin/templates/components/shared/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L3Gri6ms",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[7,\"shared/switcher-input\",[],[[\"@classNames\",\"@placeholder\",\"@value\",\"@yes\",\"@no\",\"@change\"],[\"row\",[27,[24,1],[\"display\"]],[27,[24,1],[\"selected\"]],[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],[31,173,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"change\"],null]]],null],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"yes\",\"no\",\"action\",\"computedModels\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/multiple-select.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/categories/modal/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZ8dRSZF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"categories/edit\",[],[[\"@model\",\"@destination\"],[[27,[26,0,\"AppendSingleId\"],[]],\"authenticated.categories\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/categories/modal/edit.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/components/shared/table", ["exports", "ember-lib/components/shared/sort"], function (_exports, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sort.default.extend({
    classNames: ['shared', 'table', 'panel']
  });

  _exports.default = _default;
});
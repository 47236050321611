define("gbs-admin/routes/authenticated/benefits/modal/edit/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'authenticated.benefits.modal'
      });
    }
  });

  _exports.default = _default;
});
define("gbs-admin/routes/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modelName: null,
    add: false,
    include: null,
    parent: null,
    model: function model(params) {
      if (this.get('add')) {
        return this.store.createRecord(this.get('modelName'), this.getDefaults());
      } else {
        return this.store.findRecord(this.get('modelName'), params.id, {
          include: this.get('include'),
          reload: false,
          backgroundReload: true
        });
      }
    },
    getDefaults: function getDefaults() {
      return {};
    }
  });

  _exports.default = _default;
});
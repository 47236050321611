define("gbs-admin/mixins/password", ["exports", "ember-lib/mixins/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_validators.default, {
    hasCapitalLetter: Ember.computed('model.password', function () {
      return this.hasCapitalLetterValidator(this.get('model.password'));
    }),
    hasCapitalLetterValidator: function hasCapitalLetterValidator(password) {
      if (password) {
        return !(password.toLowerCase() === password);
      } else {
        return false;
      }
    },
    //
    hasSpecialCharacter: Ember.computed('model.password', function () {
      return this.hasSpecialCharacterValidator(this.get('model.password'));
    }),
    hasSpecialCharacterValidator: function hasSpecialCharacterValidator(password) {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (format.test(password)) {
        return true;
      } else {
        return false;
      }
    },
    //
    hasLength: Ember.computed('model.password', function () {
      return this.hasLengthValidator(this.get('model.password'));
    }),
    hasLengthValidator: function hasLengthValidator(password) {
      if (password && password.length >= 8) {
        return true;
      } else {
        return false;
      }
    },
    //
    isDumbPassword: Ember.computed('model.password', function () {
      return false;
    }),
    //
    isPasswordValid: Ember.computed('model.password', function () {
      return this.passwordValidator(this.get('model.password'));
    }),
    passwordValidator: function passwordValidator(password) {
      var reference = this;
      if (this.parent) reference = this.parent;
      return reference.hasSpecialCharacterValidator(password) && reference.hasLengthValidator(password) && reference.hasCapitalLetterValidator(password);
    },
    //
    isPasswordConfirmValid: Ember.computed('password', function () {
      return this.passwordConfirmValidator(this.get('password'));
    }),
    passwordConfirmValidator: function passwordConfirmValidator(password) {
      var reference = this;
      if (this.parent) reference = this.parent;
      return reference.get('model.password') === password;
    }
  });

  _exports.default = _default;
});
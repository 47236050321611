define("gbs-admin/templates/authenticated/partner-users/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KPnvq5IQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"shared/modal\",[],[[\"@return\"],[\"authenticated.partner-users\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\\n    \"],[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/partner-users/modal.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/models/offer", ["exports", "gbs-ember-shared/models/offer", "ember-data/attr"], function (_exports, _offer, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _offer.default.extend({
    codes: (0, _attr.default)(),
    worth: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    instruction_steps: (0, _attr.default)(),
    terms_of_conditions: (0, _attr.default)()
  });

  _exports.default = _default;
});
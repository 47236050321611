define("gbs-admin/models/benefit", ["exports", "gbs-ember-shared/models/benefit", "ember-data/attr"], function (_exports, _benefit, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _benefit.default.extend({
    featured: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    search_keywords: (0, _attr.default)('string')
  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/orders/list/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JwoQQUx3",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"span\",true],[10],[1,1,0,0,\"Pending\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"span\",true],[10],[1,1,0,0,\"Completed\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"span\",true],[10],[1,1,0,0,\"Cancelled\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cancelled\",\"if\",\"completed\",\"pending\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/orders/list/status.hbs"
    }
  });

  _exports.default = _default;
});
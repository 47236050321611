define("gbs-admin/components/shared/modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'modal'],
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('body').addClass('scroll');
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)('body').removeClass('scroll');
    }
  });

  _exports.default = _default;
});
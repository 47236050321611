define("gbs-admin/components/users/edit", ["exports", "gbs-admin/components/shared/edit", "gbs-admin/mixins/password", "gbs-ember-shared/mixins/company-size"], function (_exports, _edit, _password, _companySize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend(_password.default, _companySize.default, {
    classNames: ['users', 'edit'],
    modelName: "users",
    store: Ember.inject.service(),
    customBenefits: Ember.computed('store.lengths.benefit', 'model.order.product', function () {
      if (this.get('model.order.product')) {
        var list = this.get('store').peekAll('benefit');
        list = list.filter(function (item) {
          return item.get('products.length') === 0;
        });
        return list;
      } else {
        return null;
      }
    }),
    partnerPenefits: Ember.computed('store.lengths.benefit', 'model.order.product', function () {
      if (this.get('model.order.product')) {
        return this.get('model.order.product.benefits');
      } else {
        return null;
      }
    }),
    hiddenBenefits: Ember.computed('store.lengths.benefit', 'user.order.product', 'model.order.product.benefits', function () {
      if (this.get('model.order.product')) {
        return this.get('model.order.product.benefits');
      } else {
        return null;
      }
    }),
    originOptions: Ember.computed(function () {
      return [{
        id: "admin",
        display: "Admin"
      }, {
        id: "account",
        display: "Account"
      }, {
        id: "backend",
        display: "Backend"
      }];
    }),
    emailsEnabledTransform: function emailsEnabledTransform(string) {
      if (string) {
        return string.split(' ').join('');
      } else {
        return string;
      }
    }
  });

  _exports.default = _default;
});
define("gbs-admin/initializers/analytics", ["exports", "gbs-admin/services/analytics"], function (_exports, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    app.register('service:analytics', _analytics.default);
    app.inject('route', 'analytics', 'service:analytics');
    app.inject('component', 'analytics', 'service:analytics');
  }

  var _default = {
    name: 'analytics',
    initialize: initialize
  };
  _exports.default = _default;
});
define("gbs-admin/router", ["exports", "gbs-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    headData: Ember.inject.service(),
    setTitle: function setTitle(title) {
      this.get('headData').set('title', title);
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('authenticated', {
      path: ''
    }, function () {
      this.route('admins', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('products', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('benefits', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('offers', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('import-code');
            this.route('export-code');
          });
        });
      });
      this.route('redemptions', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('employees', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('companies', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('categories', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('countries', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('transactions', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('tap-subscriptions', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('orders', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('gifts', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('gift-orders', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('users', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('create-order');
            this.route('send-welcome');
            this.route('send-payment');
          });
        });
      });
      this.route('partner-users', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {});
        });
      });
      this.route('dashboard');
      this.route('profile', function () {
        this.route('edit');
      });
      this.route('logout');
    });
  });
  var _default = Router;
  _exports.default = _default;
});
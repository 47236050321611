define("gbs-admin/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      return key;
    },
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },
    shouldSerializeHasMany: function shouldSerializeHasMany() {
      return true;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
define("gbs-admin/initializers/request", ["exports", "gbs-admin/services/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var app = arguments[1] || arguments[0];
    app.register('request:session', _request.default);
    app.inject('route', 'request', 'service:request');
    app.inject('adapter', 'request', 'service:request');
    app.inject('component', 'request', 'service:request');
    app.inject('controller', 'request', 'service:request');
  }

  var _default = {
    name: 'request',
    initialize: initialize
  };
  _exports.default = _default;
});
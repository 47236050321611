define("gbs-admin/models/redemption", ["exports", "gbs-ember-shared/models/redemption", "ember-data/attr"], function (_exports, _redemption, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _redemption.default.extend({
    company_id: (0, _attr.default)('string'),
    company_title: (0, _attr.default)('string'),
    reward_category_id: (0, _attr.default)('string')
  });

  _exports.default = _default;
});
define("gbs-admin/models/user", ["exports", "gbs-ember-shared/models/user", "ember-data/attr", "ember-data/relationships"], function (_exports, _user, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend({
    partner_api_enabled: (0, _attr.default)('boolean'),
    partner_api_key: (0, _attr.default)('string'),
    employees_count: (0, _attr.default)('number'),
    billing_address: (0, _attr.default)('string'),
    billing_country: (0, _attr.default)('string'),
    monthly_fee: (0, _attr.default)('string'),
    display: Ember.computed('company_name', 'company_short_name', function () {
      if (this.get('company_short_name')) {
        return this.get('company_short_name');
      } else {
        return this.get('company_name');
      }
    }),
    employeesLength: Ember.computed(function () {
      return this.hasMany('employees').ids().length;
    }),
    partner_benefits: (0, _relationships.hasMany)('benefit')
  });

  _exports.default = _default;
});
define("gbs-admin/components/gift-orders/edit", ["exports", "gbs-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    classNames: ['gift-orders', 'edit'],
    modelName: "gift-orders",
    column: false,
    request: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.statuses = [{
        id: "pending",
        display: "Pending"
      }, {
        id: "cancelled",
        display: "Cancelled"
      }, {
        id: "completed",
        display: "Completed"
      }];
    }
  });

  _exports.default = _default;
});
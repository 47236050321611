define("gbs-admin/adapters/application", ["exports", "ember-data", "ember-get-config"], function (_exports, _emberData, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    init: function init() {
      this._super();

      if (_emberGetConfig.default.APP.host) {
        this.set('host', _emberGetConfig.default.APP.host);
      }

      if (_emberGetConfig.default.APP.namespace) {
        this.set('namespace', _emberGetConfig.default.APP.namespace);
      }
    },
    headers: Ember.computed('session.headers.Authorization', function () {
      return this.get('session.headers');
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var prefix;

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.prefix) {
        prefix = snapshot.adapterOptions.prefix;
        delete snapshot.adapterOptions.prefix;
      } else if (query && query.prefix) {
        prefix = query.prefix;
        delete query.prefix;
      } //if ( snapshot && snapshot.adapterOptions && snapshot.adapterOptions.query ) {
      //    query = snapshot.adapterOptions.query
      //}


      var url = this._super(modelName, id, snapshot, requestType, query);

      if (prefix) {
        url = url.replace(_emberGetConfig.default.APP.host, _emberGetConfig.default.APP.host + prefix);
      }

      return url;
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/profile/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iZXWZN5m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"admins/edit\",[],[[\"@embedded\",\"@model\",\"@classNames\"],[true,[27,[26,0,\"AppendSingleId\"],[]],\"profile\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/profile/edit.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/shared/edit/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M2mFYmZY",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"p\",true],[10],[1,1,0,0,\"Password is extremely weak! You have a \"],[9,\"strong\",true],[10],[1,0,0,0,[27,[26,4,\"AppendSingleId\"],[]]],[1,1,0,0,\"%\"],[11],[1,1,0,0,\" chance to get hacked in just under a day.\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n        \"],[9,\"p\",true],[10],[1,1,0,0,\"Must be at least \"],[9,\"strong\",true],[10],[1,1,0,0,\"8\"],[11],[1,1,0,0,\" characters long.\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n        \"],[9,\"p\",true],[10],[1,1,0,0,\"Must contain at least \"],[9,\"strong\",true],[10],[1,1,0,0,\"1\"],[11],[1,1,0,0,\" special character.\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n        \"],[9,\"p\",true],[10],[1,1,0,0,\"Must contain at least \"],[9,\"strong\",true],[10],[1,1,0,0,\"1\"],[11],[1,1,0,0,\" uppercase letter.\"],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hasLength\",\"unless\",\"hasSpecialCharacter\",\"hasCapitalLetter\",\"isDumbPassword\",\"if\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/edit/password.hbs"
    }
  });

  _exports.default = _default;
});
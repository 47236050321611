define("gbs-admin/components/benefits/list", ["exports", "gbs-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _list.default.extend({
    classNames: ['benefits', 'w-2'],
    sort: function sort(a, b) {
      return a.position - b.position;
    }
  });

  _exports.default = _default;
});
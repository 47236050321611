define("gbs-admin/models/company", ["exports", "gbs-ember-shared/models/company", "ember-data/attr", "ember-data/relationships"], function (_exports, _company, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    featured: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    cover: (0, _relationships.belongsTo)('image', {
      inverse: null
    }),
    display: Ember.computed('title', function () {
      return this.title;
    })
  });

  _exports.default = _default;
});
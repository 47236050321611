define("gbs-admin/templates/components/headers/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HbYD+uE3",
    "block": "{\"symbols\":[],\"statements\":[[9,\"a\",true],[13,\"selected\",[27,[26,0,\"AppendSingleId\"],[]],null],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\",null],[10],[1,1,0,0,\"\\n\\n    \"],[9,\"span\",true],[10],[1,0,0,0,[27,[26,1,\"Expression\"],[\"model\",\"first_name\"]]],[1,1,0,0,\" \"],[1,0,0,0,[27,[26,1,\"Expression\"],[\"model\",\"last_name\"]]],[11],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[10],[11],[1,1,0,0,\"\\n\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"ul\",true],[12,\"class\",\"shared shadow\",null],[10],[1,1,0,0,\"\\n\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n        \"],[7,\"link-to\",[],[[\"@route\"],[\"authenticated.profile.edit\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n            \"],[9,\"span\",true],[10],[1,1,0,0,\"Edit Profile\"],[11],[9,\"span\",true],[10],[11],[1,1,0,0,\"\\n        \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n        \"],[7,\"link-to\",[],[[\"@route\"],[\"authenticated.logout\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n            \"],[9,\"span\",true],[10],[1,1,0,0,\"Logout\"],[11],[9,\"span\",true],[10],[11],[1,1,0,0,\"\\n        \"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"user\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/headers/profile.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/models/incentive", ["exports", "gbs-ember-shared/models/incentive"], function (_exports, _incentive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _incentive.default.extend({});

  _exports.default = _default;
});
define("gbs-admin/components/redemptions/edit", ["exports", "gbs-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    classNames: ['redemptions', 'edit'],
    modelName: "redemptions",
    store: Ember.inject.service(),
    offers: Ember.computed(function () {
      return this.get('store').peekAll('offer');
    }),
    benefits: Ember.computed(function () {
      return this.get('store').peekAll('benefit');
    })
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4tnIGuu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"companies/list\",[],[[\"@model\"],[[27,[26,0,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/companies.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/authenticated/transactions/modal/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0z7Z8/W4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"transactions/edit\",[],[[\"@embedded\",\"@model\",\"@destination\"],[true,[27,[26,0,\"AppendSingleId\"],[]],\"authenticated.transactions\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/transactions/modal/add.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/inputs/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1iMPy9J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,11,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"submit\"],null],[31,31,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"registerView\"],null],[31,57,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"unregisterView\"],null],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isInvalid\",\"isValid\",\"action\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/inputs/form.hbs"
    }
  });

  _exports.default = _default;
});
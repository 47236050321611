define("gbs-admin/models/employee", ["exports", "gbs-ember-shared/models/employee", "ember-data/attr"], function (_exports, _employee, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _employee.default.extend({
    redemptions_count: (0, _attr.default)('number'),
    company_id: (0, _attr.default)('string'),
    company_title: (0, _attr.default)('string')
  });

  _exports.default = _default;
});
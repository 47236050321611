define("gbs-admin/templates/components/common/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BadDDPjn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"shared/branding\",[],[[],[]],null],[1,1,0,0,\"\\n\\n\"],[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/common/panel.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/routes/application", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === "application") {
        this.transitionTo('login');
      }
    },
    init: function init() {
      this._super();

      console.log('version: ' + _emberGetConfig.default.version);
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/shared/switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4eWlXiqm",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"base\",null],[10],[1,1,0,0,\"\\n\\t\"],[9,\"div\",true],[12,\"class\",\"circle\",null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/switcher.hbs"
    }
  });

  _exports.default = _default;
});
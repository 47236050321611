define("gbs-admin/templates/authenticated/users/modal/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V+IWlBby",
    "block": "{\"symbols\":[],\"statements\":[[7,\"users/edit\",[],[[\"@model\",\"@destination\"],[[27,[26,0,\"AppendSingleId\"],[]],\"authenticated.users\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/authenticated/users/modal/edit.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/shared/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "85MwnF3T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"link-to\",[],[[\"@route\",\"@classNames\"],[[27,[26,0,\"AppendSingleId\"],[]],\"background\"]],null],[1,1,0,0,\"\\n\\n\"],[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"return\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/modal.hbs"
    }
  });

  _exports.default = _default;
});
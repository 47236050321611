define("gbs-admin/templates/components/shared/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hRUDFfZW",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"\\t\"],[9,\"svg\",true],[12,\"class\",\"spinner\",null],[12,\"width\",\"28px\",null],[12,\"height\",\"28px\",null],[12,\"viewBox\",\"0 0 28 28\",null],[12,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10],[1,1,0,0,\"\\n\\t\\t\"],[9,\"circle\",true],[12,\"class\",\"path\",null],[12,\"fill\",\"none\",null],[12,\"stroke-width\",\"2\",null],[12,\"stroke-linecap\",\"round\",null],[12,\"cx\",\"14\",null],[12,\"cy\",\"14\",null],[12,\"r\",\"12\",null],[10],[11],[1,1,0,0,\"\\n\\t\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"\\t\"],[9,\"svg\",true],[12,\"class\",\"spinner\",null],[12,\"width\",\"18px\",null],[12,\"height\",\"18px\",null],[12,\"viewBox\",\"0 0 18 18\",null],[12,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10],[1,1,0,0,\"\\n\\t\\t\"],[9,\"circle\",true],[12,\"class\",\"path\",null],[12,\"fill\",\"none\",null],[12,\"stroke-width\",\"2\",null],[12,\"stroke-linecap\",\"round\",null],[12,\"cx\",\"9\",null],[12,\"cy\",\"9\",null],[12,\"r\",\"8\",null],[10],[11],[1,1,0,0,\"\\n\\t\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"medium\",\"if\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/spinner.hbs"
    }
  });

  _exports.default = _default;
});
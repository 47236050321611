define("gbs-admin/components/shared/resource-paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'length'],
    recordCount: Ember.computed('model', function () {
      return Number(this.get('model').get('meta.record-count'));
    }),
    availablePages: Ember.computed('recordCount', function () {
      return Math.ceil(this.get('recordCount') / 100);
    }),
    visiblePages: Ember.computed('model', 'availablePages', function () {
      var pages = [];

      for (var i = 0; i < this.get('availablePages'); i++) {
        pages.push({
          label: i + 1,
          value: i
        });
      }

      return pages;
    })
  });

  _exports.default = _default;
});
define("gbs-admin/models/category", ["exports", "gbs-ember-shared/models/category", "ember-data/attr", "ember-data/relationships"], function (_exports, _category, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _category.default.extend({
    display: Ember.computed('title', function () {
      return this.title;
    }),
    keywords: (0, _attr.default)('string'),
    image: (0, _relationships.belongsTo)('image', {
      inverse: null
    })
  });

  _exports.default = _default;
});
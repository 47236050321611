define("gbs-admin/templates/components/shared/switcher-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgPxrAor",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"inputs/text-input\",[],[[\"@lead\",\"@placeholder\",\"@value\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],[31,72,6,[27,[26,5,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\\n    \"],[16,1,null],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"shared/switcher\",[],[[\"@value\",\"@change\"],[[27,[26,4,\"AppendSingleId\"],[]],[31,178,6,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[]],\"change\"],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lead\",\"placeholder\",\"no\",\"yes\",\"value\",\"status\",\"action\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/switcher-input.hbs"
    }
  });

  _exports.default = _default;
});
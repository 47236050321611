define("gbs-admin/components/orders/list/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['shared', 'table-status', 'button', 'small'],
    classNameBindings: ['pending:black', 'completed:green', 'cancelled:red'],
    pending: Ember.computed.alias('model.isStatusPending'),
    completed: Ember.computed.alias('model.isStatusCompleted'),
    cancelled: Ember.computed.alias('model.isStatusCancelled')
  });

  _exports.default = _default;
});
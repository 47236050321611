define("gbs-admin/models/transaction", ["exports", "gbs-ember-shared/models/transaction"], function (_exports, _transaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transaction.default.extend({
    display: Ember.computed('id', function () {
      return this.id;
    })
  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/inputs/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Xm1tyi0",
    "block": "{\"symbols\":[\"model\",\"index\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[9,\"option\",true],[13,\"value\",[27,[24,1],[\"id\"]],null],[13,\"selected\",[31,77,2,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],[27,[24,1],[\"id\"]]],null],null],[10],[1,0,0,0,[27,[24,1],[\"display\"]]],[11],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"eq\",\"models\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/inputs/select.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/routes/authenticated/redemptions/modal/add", ["exports", "gbs-admin/routes/authenticated/redemptions/modal/edit", "gbs-admin/mixins/add"], function (_exports, _edit, _add) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend(_add.default, {});

  _exports.default = _default;
});
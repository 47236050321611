define("gbs-admin/routes/authenticated/partner-users/modal/edit", ["exports", "gbs-admin/routes/view"], function (_exports, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _view.default.extend({
    modelName: 'partner-user',
    parent: 'authenticated.partner-users.list' //include: 'image',

  });

  _exports.default = _default;
});
define("gbs-admin/templates/components/shared/edit/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OqqDPHsP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"left\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"h4\",true],[10],[1,1,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"p\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"right\",null],[10],[1,1,0,0,\"\\n    \"],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lead\",\"title\",\"if\"]}",
    "meta": {
      "moduleName": "gbs-admin/templates/components/shared/edit/section.hbs"
    }
  });

  _exports.default = _default;
});
define("gbs-admin/initializers/customerio", ["exports", "gbs-admin/services/customerio"], function (_exports, _customerio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    app.register('service:customerio', _customerio.default);
    app.inject('route', 'customerio', 'service:customerio');
    app.inject('component', 'customerio', 'service:customerio');
  }

  var _default = {
    name: 'customerio',
    initialize: initialize
  };
  _exports.default = _default;
});
define("gbs-admin/routes/authenticated/users/modal/edit/create-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var user = this.modelFor('authenticated.users.modal.edit');
      var product = this.store.peekAll('product').objectAt(0);
      return this.store.createRecord('order', {
        owner: user,
        product: product,
        period: "monthly",
        status: 'completed',
        free: true
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'authenticated.users.modal'
      });
    }
  });

  _exports.default = _default;
});
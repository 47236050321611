define("gbs-admin/components/headers/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['submenu', 'profile'],
    attributeBindings: ['selected'],
    tagName: 'li',
    selected: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName.indexOf('.profile') !== -1;
    })
  });

  _exports.default = _default;
});
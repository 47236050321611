define("gbs-admin/components/offers/list", ["exports", "gbs-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _list.default.extend({
    classNames: ['benefits', 'w-2'],
    sort: function sort(a, b) {
      if (a.get('benefit.display')) {
        return a.get('benefit.display').localeCompare(b.get('benefit.display'));
      } else {
        return false;
      }
    }
  });

  _exports.default = _default;
});